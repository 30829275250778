<template>
  <NuxtLayout name="default">
    <div class="flex flex-col justify-between h-full ">

      <div class="absolute inset-0 bottom-[40%]">
        <div class="absolute inset-0 bg-[url('/png/welcome.png')] bg-cover bg-bottom"></div>
        <div class="absolute inset-0 bg-theme-green opacity-60"></div>
      </div>
      <div class="z-10">
        <img class="w-12 h-12 m-6" src="/svg/logo.svg">
        <div class="font-black text-5xl text-center text-white p-4">Bienvenue</div>
      </div>
      <div class="relative bg-white rounded-t-3xl">
        <slot></slot>
      </div>
    </div>
  </NuxtLayout>
</template>

<style lang="stylus" scoped>
</style>
<script setup lang="ts">
</script>
